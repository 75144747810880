<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="56"
    viewBox="0 0 84 56"
  >
    <g transform="translate(-229 -1035)">
      <rect
        class="a"
        width="84"
        height="56"
        rx="4"
        transform="translate(229 1035)"
      />
      <path
        class="b"
        d="M0,0H38a4,4,0,0,1,4,4V52a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
        transform="translate(271 1035)"
      />
      <path
        class="c"
        d="M3343,1255.107h84"
        transform="translate(-3114 -205.107)"
      />
      <rect
        class="d"
        width="12"
        height="8"
        rx="4"
        transform="translate(294 1076)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FeaturePayment",
};
</script>

<style lang="scss" scoped>
.a {
  fill: #05b5bc;
}
.b {
  fill: #2d323c;
  opacity: 0.152;
}
.c {
  fill: none;
  stroke: #fff;
  stroke-width: 4px;
}
.d {
  fill: #fff;
}
</style>